<template>
  <div v-cloak>
    <transition name="fade" appear appear-active-class="fade-enter-active">
      <div class="wrap">

        <h1>Образовательная траектория</h1>


        <div class="front-page">
          <div class="left">
            <div>
              <div class="icon">
                <svg width="20" height="20" viewBox="0 0 20 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M10 0C4.48 0 0 4.48 0 10C0 15.52 4.48 20 10 20C15.52 20 20 15.52 20 10C20 4.48 15.52 0 10 0ZM5.07 16.28C5.5 15.38 8.12 14.5 10 14.5C11.88 14.5 14.51 15.38 14.93 16.28C13.57 17.36 11.86 18 10 18C8.14 18 6.43 17.36 5.07 16.28ZM16.36 14.83C14.93 13.09 11.46 12.5 10 12.5C8.54 12.5 5.07 13.09 3.64 14.83C2.62 13.49 2 11.82 2 10C2 5.59 5.59 2 10 2C14.41 2 18 5.59 18 10C18 11.82 17.38 13.49 16.36 14.83ZM10 4C8.06 4 6.5 5.56 6.5 7.5C6.5 9.44 8.06 11 10 11C11.94 11 13.5 9.44 13.5 7.5C13.5 5.56 11.94 4 10 4ZM10 9C9.17 9 8.5 8.33 8.5 7.5C8.5 6.67 9.17 6 10 6C10.83 6 11.5 6.67 11.5 7.5C11.5 8.33 10.83 9 10 9Z"
                      fill="#69B3E7"/>
                </svg>
              </div>
              <h2>Начать оказание сервиса</h2>
              <router-link link to="/new">ПЕРЕЙТИ
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.589996 10.58L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.58Z"
                        fill="#0033A0"/>
                </svg>
              </router-link>
            </div>
            <div>
              <div class="icon">
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M4 9H6V11H4V9ZM18 4V18C18 19.1 17.1 20 16 20H2C0.89 20 0 19.1 0 18L0.00999999 4C0.00999999 2.9 0.89 2 2 2H3V0H5V2H13V0H15V2H16C17.1 2 18 2.9 18 4ZM2 6H16V4H2V6ZM16 18V8H2V18H16ZM12 11H14V9H12V11ZM8 11H10V9H8V11Z"
                      fill="#69B3E7"/>
                </svg>
              </div>
              <h2>Календарь посещений<br>Активные задачи</h2>
              <router-link link to="/calendar">ПЕРЕЙТИ
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.589996 10.58L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.58Z"
                        fill="#0033A0"/>
                </svg>
              </router-link>
            </div>
            <div>
              <div class="icon">
                <svg width="18" height="20" viewBox="0 0 18 20" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M4 14H11V16H4V14ZM4 10H14V12H4V10ZM4 6H14V8H4V6ZM16 2H11.82C11.4 0.84 10.3 0 9 0C7.7 0 6.6 0.84 6.18 2H2C1.86 2 1.73 2.01 1.6 2.04C1.21 2.12 0.86 2.32 0.59 2.59C0.41 2.77 0.26 2.99 0.16 3.23C0.0600001 3.46 0 3.72 0 4V18C0 18.27 0.0600001 18.54 0.16 18.78C0.26 19.02 0.41 19.23 0.59 19.42C0.86 19.69 1.21 19.89 1.6 19.97C1.73 19.99 1.86 20 2 20H16C17.1 20 18 19.1 18 18V4C18 2.9 17.1 2 16 2ZM9 1.75C9.41 1.75 9.75 2.09 9.75 2.5C9.75 2.91 9.41 3.25 9 3.25C8.59 3.25 8.25 2.91 8.25 2.5C8.25 2.09 8.59 1.75 9 1.75ZM16 18H2V4H16V18Z"
                      fill="#69B3E7"/>
                </svg>
              </div>
              <h2>Архив выданных заключений</h2>
              <router-link link to="/archive">ПЕРЕЙТИ
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.589996 10.58L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.58Z"
                        fill="#0033A0"/>
                </svg>
              </router-link>
            </div>
            <div>
              <div class="icon">
                <svg width="18" height="18" viewBox="0 0 18 18" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path
                      d="M16 0H2C0.9 0 0 0.9 0 2V9C0 10.1 0.9 11 2 11H16C17.1 11 18 10.1 18 9V2C18 0.9 17.1 0 16 0ZM2 7H5.13C5.34 7.78 5.8 8.47 6.4 9H2V7ZM16 9H11.6C12.2 8.47 12.66 7.78 12.87 7H16V9ZM16 5H11V6C11 7.07 10.07 8 9 8C7.93 8 7 7.07 7 6V5H2V2H16V5ZM11 12V13C11 13.47 10.81 13.9 10.52 14.25C10.15 14.7 9.6 15 9 15C8.4 15 7.85 14.7 7.48 14.25C7.19 13.9 7 13.47 7 13V12H0V16C0 17.1 0.9 18 2 18H16C17.1 18 18 17.1 18 16V12H11ZM2 14H5.13C5.15 14.09 5.19 14.17 5.22 14.25C5.46 14.93 5.87 15.53 6.4 16H2V14ZM16 16H11.6C12.14 15.53 12.55 14.93 12.78 14.25C12.81 14.17 12.85 14.09 12.87 14H16V16Z"
                      fill="#69B3E7"/>
                </svg>
              </div>
              <h2>Справочники</h2>
              <router-link link to="/directories">ПЕРЕЙТИ
                <svg width="8" height="12" viewBox="0 0 8 12" fill="none"
                     xmlns="http://www.w3.org/2000/svg">
                  <path d="M0.589996 10.58L5.17 6L0.589996 1.41L2 0L8 6L2 12L0.589996 10.58Z"
                        fill="#0033A0"/>
                </svg>
              </router-link>
            </div>
          </div>
          <div class="right">
            <h2>Ближайшие посещения</h2>
            <table class="table">
              <thead>
              <tr>
                <th>Номер</th>
                <th>Дата</th>
                <th>ФИО</th>
              </tr>
              </thead>
              <tbody>
              <tr v-for="(application, applicationIndex) in applications"
                  :key="applicationIndex"
                  @click="$router.push('/task1/'+application.id)"
              >
                <td>{{ applicationIndex + 1 }}</td>
                <td>{{ application.localDateTime | dateFormat}}</td>
                <td>{{ application.lastName }} {{ application.firstName }} {{ application.middleName }}</td>
              </tr>
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </transition>
  </div>
</template>

<script>

import api from "@/modules/api";

export default {
  name: 'Home',
  data() {
    return {
      applications: [],
    }
  },
  filters: {
    dateFormat: function (date) {
      if (date == null) {
        return ''
      }
      const mdate = new Date(date)
      const options = {
        //timeZone: 'Europe/Moscow',
        year: 'numeric',
        month: 'numeric',
        day: 'numeric',
        hour: 'numeric',
        minute: 'numeric',
        second: 'numeric'
      }
      return mdate.toLocaleDateString('ru-RU', options)
    }
  },
  methods: {
    async get10SoonestApplications() {
      const currentTime = new Date()
      currentTime.setTime(currentTime.getTime() - currentTime.getTimezoneOffset()*60*1000 - 60*1000*60)
      console.log('get10SoonestApplications')
      const req = await api.get('/applications/search/findAllByLocalDateTimeAfterAndAssessmentIdIsNull?time=' + currentTime.toISOString() + '&page=0&size=10&sort=localDateTime,desc')
      if (req.ok) {
        console.log(req.payload._embedded.applications)
        return req.payload._embedded.applications.reverse()
      }
      alert('Ошибка при обращении к серверу!')
    }
  },
  async beforeMount() {
    let applications = await this.get10SoonestApplications()
    applications.forEach(function (application) {
      const date = new Date(application.localDateTime)
      application.localDateTime = date
    })
    this.applications = applications
  },
}
</script>

<style lang="scss">
@import "../styles/main.scss";
</style>


<style scoped lang="scss">
</style>
